import React from 'react';
import { useEffect, useState } from 'react';
import { GetProductLinkTagCopyable, GetUserLinkTag } from "../../components/getPageLinks";
import { Button, Card, Col, Descriptions, Popconfirm, Row, Space, Table, Tabs, Tag, Typography } from "antd";
import { CloseCircleOutlined, EyeOutlined, QuestionCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { useParams } from "react-router-dom";
import Moment from 'react-moment';
import { Spinner, SpinnerInline } from "../../components/spinners";
import { GetTagFromValue } from "../../components/formattingValues";
import { BreadcrumbsCampaignsCampaign } from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { HTTPDelete } from "../../components/backend/httpDELETE";
import { Helmet } from "react-helmet";

const { Paragraph } = Typography

export const GetCampaign = () => {
    const prevCampaignRenderer = (date1, date2) => {
        const dateLast = new Date(date1)
        const dateStartCurrentCampaign = new Date(date2)

        dateStartCurrentCampaign.setUTCMonth(dateStartCurrentCampaign.getUTCMonth() - 1)

        if (dateLast < dateStartCurrentCampaign) {
            return <></>
        }
        return <Tag color={'volcano'}><Moment format="YYYY.MM.DD" date={dateLast} /></Tag>
    }
    const { id } = useParams();
    const [name, setName] = useState(<SpinnerInline />);
    const [data, setData] = useState([]);
    const [dataCampaign, setDataCampaign] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [err, setErr] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });


    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
        },
        {
            title: 'SKU',
            dataIndex: 'ItemSKU',
            key: 'ItemSKU',
            render: (_, record) => (
                <>{record.ItemSKU !== "" ? GetProductLinkTagCopyable(record.ItemSKU) : <></>}</>
            ),
        },
        {
            title: 'Prev campaign ends',
            dataIndex: 'Prev_campaign_ends',
            key: 'Prev_campaign_ends',
            render: (_, record) => (prevCampaignRenderer(record.DateOfLastCampaign.Time, dataCampaign.StartDate)),
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                data.length >= 1 ? (
                    <>
                        <Space wrap>
                            <Button type="primary" href={`/products/${record.ItemSKU}`} target={"_blank"}
                                rel={"noreferrer"}>
                                <EyeOutlined />
                            </Button>
                            <Popconfirm
                                title="Delete product from campaign"
                                description="You can't add it again, only recreate campaign"
                                onConfirm={() => handleDeleteProduct(record.CampaignID, record.ItemSKU)}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button type="dashed" danger>
                                    <CloseCircleOutlined />
                                </Button>
                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ];

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/products/campaigns?cid=${id}&page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                        setName(id)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setDataCampaign(result.result.Data);
                        setName(result.result.Data.Name)
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setName(id)
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const fetchTableOnly = () => {
        setLoadingTable(true);
        GetRequest(getBackendLink() + `/api/v1/products/campaigns?cid=${id}&page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoadingTable(false);
                },
                (error) => {
                    setLoadingTable(false);
                    NotificationError('Error', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchTableOnly();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleDelete = (key = []) => {
        setLoading(true)
        HTTPDelete(getBackendLink() + `/api/v1/products/campaigns/delete`, {
            "campaign_ids": [key],
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Deleted!')
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoading(false)
    };

    const handleDeleteProduct = (camp = '', sku = '') => {
        setLoading(true)
        HTTPDelete(getBackendLink() + `/api/v1/products/campaigns/delete_product`, {
            "campaign_id": camp,
            "sku": sku,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Deleted!')
                    let newData = data

                    if (Array.isArray(sku)) {
                        sku.forEach((element) => {
                            newData = newData.filter((item) => item.ItemSKU !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.ItemSKU !== sku);
                    }

                    setData(newData);
                    dataCampaign.NumberOfProducts = dataCampaign.NumberOfProducts - 1
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoading(false)
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };


    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [errLogs, setErrLogs] = useState('');
    const [logsTableParams, setLogsTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 500,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleLogsTableChange = (pagination, filters, sorter) => {
        setLogsTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== logsTableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setLogs([]);
        }
    };

    const fetchLogs = () => {
        let sku = "all"
        let cid = id

        setLoadingLogs(true);
        GetRequest(getBackendLink() + `/api/v1/products/campaigns/get_logs?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&cid=${cid}&sku=${sku}&author=all&level=0&before=all&after=all`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErrLogs(result.error)
                    } else {
                        setErr("")
                        setLogs(result.result.Records);
                        setLogsTableParams({
                            ...logsTableParams,
                            pagination: {
                                ...logsTableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoadingLogs(false);
                },
                (error) => {
                    setLoadingLogs(false);
                    setErrLogs(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line
    }, [JSON.stringify(logsTableParams)]);


    const columnsLogs = [
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'CreatedAt',
            render: (_, record) => (
                <><Moment format="YYYY.MM.DD hh:mm" date={record.CreatedAt} /></>
            ),
        },
        {
            title: 'ProductID',
            dataIndex: 'ProductID',
            key: 'ProductID',
            render: (_, record) => (
                <>{record.ProductID !== "" ? <>{GetProductLinkTagCopyable(record.ProductID)}</> : record.ProductID}</>
            ),
        },
        {
            title: 'Level',
            dataIndex: 'LogLevelName',
            key: 'LogLevelName',
            render: (_, record) => (
                <>{GetTagFromValue(record.LogLevelName)}</>
            ),
        },
        {
            title: 'Text',
            dataIndex: 'Text',
            key: 'Text',
        },
        {
            title: 'Author',
            dataIndex: 'Author',
            key: 'Author',
            render: (_, record) => (
                <>{record.AuthorName === "" ? <>Unknown</> : GetUserLinkTag(record.Author, record.AuthorName)}</>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Campaign #{id} || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsCampaign id={name} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button href={"/campaigns"}
                                type="primary"
                            >
                                Campaign list
                            </Button>
                            <Button href={getBackendLink() + `/api/v1/products/campaigns/get_file?${id}`}
                                type="primary"
                                style={{ backgroundColor: "green" }}
                                disabled={dataCampaign.NumberOfProducts === undefined || dataCampaign.NumberOfProducts < 1}
                            >
                                Download file <CloudDownloadOutlined />
                            </Button>
                            {dataCampaign.NumberOfProducts === undefined || dataCampaign.NumberOfProducts < 1
                                ?
                                <Button type="dashed" danger
                                    disabled={true}
                                >
                                    <CloseCircleOutlined />
                                </Button>
                                :
                                <Popconfirm
                                    title="Delete row"
                                    description="Are you sure to delete this campaign?"
                                    onConfirm={() => handleDelete(dataCampaign.CampaignID)}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Button type="dashed" danger>
                                        <CloseCircleOutlined />
                                    </Button>
                                </Popconfirm>
                            }
                        </Space>
                    </Col>
                </Row>
                <Row className="bottom-1em"
                    style={{
                        marginBottom: "1em",
                    }}>
                    <Col xs={24} xl={24}>
                        {err !== ""
                            ?
                            <div className="container-1200" style={{ "marginTop": "1em" }}>
                                <ErrorRow prefix={"Campaign"} err={err} />
                            </div>
                            : <></>
                        }
                    </Col>
                </Row>
                <Row className="bottom-1em"
                    style={{
                        marginBottom: "1em",
                    }}>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="BasicData"
                            items={[
                                {
                                    label: 'Basic campaign data',
                                    key: 'BasicData',
                                    children: loading === true ? <Spinner /> :
                                        <>
                                            <Card
                                                title={<Typography.Title copyable={{ text: dataCampaign.CampaignID }}
                                                    level={4}
                                                    style={{ margin: 0 }}>{dataCampaign.CampaignID}</Typography.Title>}
                                                bordered={false}
                                            >
                                                <Descriptions>
                                                    <Descriptions.Item label="ID">
                                                        {<Paragraph copyable={{ text: dataCampaign.CampaignID }}
                                                            style={{ margin: 0 }}>{dataCampaign.CampaignID}</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Name">
                                                        {<Paragraph copyable={{ text: dataCampaign.Name }}
                                                            style={{ margin: 0 }}>{dataCampaign.Name}</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Discount">
                                                        {dataCampaign.DiscountPercent}%
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Brand">
                                                        {<Paragraph copyable={{ text: dataCampaign.BrandName }}
                                                            style={{ margin: 0 }}>{dataCampaign.BrandName} ({dataCampaign.BrandID})</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Category">
                                                        {dataCampaign.CategoryName} ({dataCampaign.CategoryID})
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Country">
                                                        {dataCampaign.CountryName} ({dataCampaign.CountryID})
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Dates">
                                                        {<Moment format="YYYY.MM.DD"
                                                            date={dataCampaign.StartDate} />} - {
                                                            <Moment
                                                                format="YYYY.MM.DD" date={dataCampaign.DueDate} />}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Products">
                                                        {dataCampaign.NumberOfProducts}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Created">
                                                        <><Moment format="YYYY.MM.DD HH:MM"
                                                            date={dataCampaign.CreatedAt} /> ({GetUserLinkTag(dataCampaign.CreatedBy, dataCampaign.CreatedByName)})
                                                        </>

                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Card>
                                            <Table
                                                dataSource={data}
                                                columns={columns}
                                                loading={loading === true || loadingTable === true}
                                                pagination={tableParams.pagination}
                                                rowKey={(record) => record.ID}
                                                size={"small"}
                                                onChange={handleTableChange}
                                                showSizeChanger={true}
                                            />
                                        </>,
                                },
                                {
                                    label: 'Logs for last 365 days',
                                    key: 'Logs',
                                    children: loadingLogs === true ? <Spinner /> :
                                        <>
                                            {errLogs !== "" ?
                                                <div className="container-1200" style={{ "marginTop": "1em" }}>
                                                    <ErrorRow prefix={"Campaign"} err={errLogs} />
                                                </div> : <></>}
                                            <Table
                                                columns={columnsLogs}
                                                rowKey={(record) => record.product}
                                                dataSource={logs}
                                                pagination={logsTableParams.pagination}
                                                loading={loadingLogs}
                                                size={"small"}
                                                onChange={handleLogsTableChange}
                                            />
                                        </>,
                                },
                            ]}
                        />

                    </Col>
                </Row>
            </div>
        </>
    );
}