import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Form, Popover, Tag } from 'antd';
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { ErrorRow } from "../../../components/errorBlock";
import { NotificationError } from "../../../components/notifications";
import {
    MonitorOutlined,
} from "@ant-design/icons";
import { PostRequest } from "../../../components/backend/postRequest";
import { GetUserSelect } from "../../../components/getUsers";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../../components/dateAndTime";
import {
    EventLevelsMap,
    EventSourcesMap,
    GetEventSourcesSelect,
    GetEventLevelsSelect
} from "../../../components/getEventsData";
import {
    GetCategoriesSelect,
    GetSubcategoriesSelect
} from "../../../components/getEventCategories";
import { BreadcrumbsEvents } from "../../../components/breadcrumbs/breadcrumbsStat";
import { GetApplicationRunLink, GetEventLink, GetUserLinkElementAvatar } from "../../../components/getPageLinks";
import { Helmet } from "react-helmet";
import { TranslateCategory, TranslateSubCategory } from "../../../components/getEventCategories";

export const EventsList = (props) => {
    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const columns = [
        {
            title: 'Active',
            dataIndex: 'IsResolved',
            key: 'IsResolved',
            render: (_, record) => (
                <>
                    {
                        !record.IsResolved
                            ?
                            <Popover content={"Event is active and not resolved"} title="Not resolved">
                                Yes
                            </Popover>
                            :
                            <Popover content={`Event was resolved by ${record.ResolvedByName}`} title="Resolved">
                                No
                            </Popover>
                    }
                </>
            ),
        },
        {
            title: 'Theme',
            dataIndex: 'Head',
            key: 'Head',
            render: (_, record) => (
                <>
                    {
                        record.IsResolved
                            ?
                            record.EventID ? GetEventLink(record.EventID, record.Head) : record.Head
                            :
                            <strong>{record.EventID ? GetEventLink(record.EventID, record.Head) : record.Head}</strong>
                    }
                </>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'Source',
            dataIndex: 'Source',
            key: 'Source',
            render: (_, record) => (
                <>{EventSourcesMap[record.Source] ? EventSourcesMap[record.Source].tag : record.Source}</>
            ),
        },
        {
            title: 'Author',
            dataIndex: 'CreatedBy',
            key: 'CreatedBy',
            render: (_, record) => (
                <>{record.CreatedBy !== "" ? <><GetUserLinkElementAvatar id={record.CreatedBy} avatar={record.CreatedByAvatar} /></> :
                    <></>}</>
            ),
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            render: (_, record) => (
                <>{EventLevelsMap[record.Priority] ? EventLevelsMap[record.Priority].tag : record.Priority}</>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'Category',
            render: (_, record) => (
                <>{record.EventCategory !== undefined ? TranslateCategory(record.EventCategory) : <></>}{record.EventSubcategory !== undefined ? TranslateSubCategory(record.EventSubcategory) : <></>}</>
            ),
        },
        {
            title: 'Run ID',
            dataIndex: 'RunID',
            key: 'RunID',
            render: (_, record) => (
                <>{GetApplicationRunLink(record.RunID)}</>
            ),
        },
    ];

    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [authorUser, setAuthorUser] = useState("");
    const [priority, setPriority] = useState("");
    const [source, setSource] = useState("");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const fetchData = () => {
        setLoading(true);
        PostRequest(getBackendLink() + `/api/v1/notifications/events/list/json`, {
            page: tableParams.pagination.current,
            size: tableParams.pagination.pageSize,
            created_by: authorUser,
            before: before,
            after: after,
            source: source,
            priority: priority,
            event_category: category,
            event_sub_category: subCategory,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                        setTotal(result.result.Total);
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>Event list || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsEvents />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Runs"} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Event list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsEvents />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={12}>
                        <Form.Item label="Dates" name="Dates"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Before and after',
                                },
                            ]}>
                            <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={12}>
                        Total: <Tag color={"purple"}>{total}</Tag>
                    </Col>



                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={8}>
                        <Form.Item
                            name={`Priority`}
                            label={`Priority`}
                        >
                            <GetEventLevelsSelect onChange={setPriority} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Form.Item
                            name={`Source`}
                            label={`Source`}
                        >
                            <GetEventSourcesSelect onChange={setSource} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Form.Item
                            name={`Author`}
                            label={`Author`}
                        >
                            <GetUserSelect grants={props.grants} onChange={setAuthorUser} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={8}>
                        <Form.Item
                            name={`Category`}
                            label={`Category`}
                        >
                            <GetCategoriesSelect onChange={setCategory} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Form.Item
                            name={`Sub category`}
                            label={`Sub category`}
                        >
                            <GetSubcategoriesSelect onChange={setSubCategory} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={fetchData}
                        >
                            Get events <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.EventID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
