import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Form, Alert, Input } from 'antd';
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { ErrorRow } from "../../components/errorBlock";
import { NotificationError } from "../../components/notifications";
import { MonitorOutlined, } from "@ant-design/icons";
import { GetApplicationRunLink, GetUserLink } from "../../components/getPageLinks";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../components/dateAndTime";
import { GetUserSelect } from "../../components/getUsers";
import {
    GetEventLevelsSelect,
    GetEventSourcesSelect,
    EventLevelsMap,
    EventSourcesMap
} from "../../components/getEventsData";
import { IsCrititcal, IsDanger, IsError, IsWarning } from "../../components/checkingValues";
import { BreadcrumbsLogs } from "../../components/breadcrumbs/breadcrumbsStat";
import { Helmet } from "react-helmet";

const columns = [
    {
        title: 'Time',
        dataIndex: 'Time',
        key: 'Time',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.Timing.CreatedAt.seconds * 1000} /></>
        ),
    },
    {
        title: 'Source',
        dataIndex: 'logSource',
        key: 'logSource',
        render: (_, record) => (
            <>{record.LogSource !== undefined && record.LogSource !== null ? EventSourcesMap[record.LogSource].tag : <>???</>}</>
        ),
    },
    {
        title: 'Level',
        dataIndex: 'logLevel',
        key: 'logLevel',
        render: (_, record) => (
            <>{record.LogLevel !== undefined && record.LogLevel !== null ? EventLevelsMap[record.LogLevel].tag : <>???</>}</>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'RunID',
        key: 'RunID',
        render: (_, record) => (
            <>{GetApplicationRunLink(record.RunID)}</>
        ),
    },
    {
        title: 'Author',
        dataIndex: 'LogAuthor',
        key: 'LogAuthor',
        render: (_, record) => (
            <>{record.Author !== "" ? <><a href={GetUserLink(record.Author)}>{record.AuthorName ? record.AuthorName : record.Author}</a></> :
                <></>}</>
        ),
    },
    {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: (_, record) => (
            IsWarning(record.LogLevel)
                ? <Alert message={record.Text} type="warning" />
                :
                IsError(record.LogLevel) || IsDanger(record.LogLevel) || IsCrititcal(record.LogLevel) ?
                    <Alert message={record.Text} type="error" />
                    : <>{record.Text}</>
        ),
    },
];

export const LogsList = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState("");
    const [level, setLevel] = useState("");
    // eslint-disable-next-line
    const [authorUser, setAuthorUser] = useState("");
    //  const [runID, setRunID] = useState("");
    const [form] = Form.useForm();
    const runID = Form.useWatch('run_id', form);
    // eslint-disable-next-line
    const [targetUser, setTargetUser] = useState("");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const fetchData = () => {
        let localRunID = ""
        if (runID !== undefined) {
            localRunID = runID
        }

        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/logs/get_list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&source=${source}&level=${level}&author=${authorUser}&target=${targetUser}&before=${before}&after=${after}&run_id=${localRunID}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };



    return (
        <>
            <Helmet>
                <title>Ecosystem logs || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsLogs />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Form form={form} layout="vertical" autoComplete="off">
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item label="Dates" name="Dates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Before and after',
                                    },
                                ]}>
                                <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                name={`Source`}
                                label={`Source`}
                            >
                                <GetEventSourcesSelect onChange={setSource} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                name={`Author`}
                                label={`Author`}
                            >
                                <GetUserSelect grants={props.grants} setUser={setAuthorUser} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                name={`Level`}
                                label={`Level`}
                            >
                                <GetEventLevelsSelect onChange={setLevel} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item name="run_id" label="ApplicationsRunsRun ID">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>


                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={fetchData}
                        >
                            Get logs <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.product}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
