import React from 'react';
import { Alert, Button, Col, Form, Row, Space, Tour } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { useEffect, useState, useRef } from 'react';
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { PostRequest } from "../../components/backend/postRequest";
import {
    DatePicker,
    InputNumber,
    Select,
} from 'antd';
import { message } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    BreadcrumbsCampaignsCreate
} from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { StringifiedCategory } from "../../components/product";
import { BrandsSelect } from "../../subpages/products/brands";
import { GetCategories } from "../../components/backend/getters";
import { Helmet } from "react-helmet";

const { RangePicker } = DatePicker;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};

export const CampaignCreate = () => {
    const refBrand = useRef(null);
    const refDates = useRef(null);
    const refMarketsInclude = useRef(null);
    const refMarketsExclude = useRef(null);
    const refProducts = useRef(null);
    const refCats = useRef(null);
    const refCreate = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Choose brand',
            description: 'First step is to pick a brand. After brand is chosen or changed, items & categories will appear',
            target: () => refBrand.current,
        },
        {
            title: 'Pick dates',
            description: 'Dates are necessary',
            target: () => refDates.current,
        },
        {
            title: 'Include markets',
            description: 'Empty markets = use all available (except ones in Exclude field). Some (like DE) will not create any campaign for now',
            target: () => refMarketsInclude.current,
        },
        {
            title: 'Exclude markets',
            description: 'When you change include/exclude values, pair fields will change available set of items. If you send some element in both include + exclude fields, it will be EXCLUDED',
            target: () => refMarketsExclude.current,
        },
        {
            title: 'Pick products',
            description: 'Empty field = use all products for the brand (except ones in Exclude field)',
            target: () => refProducts.current,
        },
        {
            title: 'Categories',
            description: 'Empty field = create one campaign per each category + market pair',
            target: () => refCats.current,
        },
        {
            title: 'Press Create',
            description: 'Campaigns may take up to 20 seconds to be created. After this you will see list of campaigns or errors below',
            target: () => refCreate.current,
        },
    ];

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'loading',
            content: 'Sending requests...',
            duration: 0,
        });
    };

    const [form] = Form.useForm();
    const dueDateValue = Form.useWatch('Dates', form);

    const [countsErr, setCountsErr] = useState('');
    const [counts, setCounts] = useState([]);
    const [countsIn, setCountsIn] = useState([]);
    const [countsEx, setCountsEx] = useState([]);
    const [countsLoading, setCountsLoading] = useState(true);

    const fetchCountries = (page, size) => {
        setCountsLoading(true);
        GetRequest(getBackendLink() + `/api/v1/products/countries?page=${page}&size=${size}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setCountsErr(result.error)
                    } else {
                        setCountsErr("")

                        let counts = []
                        result.result.Records.map((x) => (
                            counts.push({
                                ID: x.ID,
                                Name: x.Name,
                                CurrencyAtBlock: x.CurrencyAtBlock,
                                Language: x.Language,
                                LandISO: x.LandISO,
                                ListName: `${x.LandISO} (${x.Name})`,
                            })
                        ))

                        setCounts(counts);
                        setCountsIn(counts);
                        setCountsEx(counts);
                    }
                    setCountsLoading(false);
                },
                (error) => {
                    setCountsLoading(false);
                    NotificationError('Countries', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchCountries(1, 10000);
    }, []);

    const changeMarketsEx = (keys = []) => {
        setCountsLoading(true)
        if (keys.length === 0) {
            setCountsEx(counts)
        } else {
            let newData = countsEx
            keys.forEach((element) => {
                newData = newData.filter((item) => item.ID !== element);
            }
            );
            setCountsEx(newData);
        }
        setCountsLoading(false)
    }

    const changeMarketsIn = (keys = []) => {
        setCountsLoading(true)
        if (keys.length === 0) {
            setCountsIn(counts)
        } else {
            let newData = countsIn
            keys.forEach((element) => {
                newData = newData.filter((item) => item.ID !== element);
            }
            );
            setCountsIn(newData);
        }
        setCountsLoading(false)
    }


    const [lastCampsErr, setLastCampsErr] = useState('');
    const [lastCampsHTML, setLastCampsHTML] = useState([]);

    // eslint-disable-next-line
    const [lastCampsLoading, setLastCampsLoading] = useState(false);

    const fetchLastCamps = (skus, date = []) => {
        if (date === null || date === undefined) {
            return
        }

        if (date.length < 2 || skus === undefined) {
            return
        }

        let vals = []
        skus.forEach((sku) => {
            vals.push(sku.Product.ItemSKU)
        }
        );

        const dateParsed = new Date(date[0]);
        dateParsed.setUTCMonth(dateParsed.getUTCMonth() - 1)
        const year = dateParsed.toLocaleString('default', { year: 'numeric' });
        const month = dateParsed.toLocaleString('default', { month: '2-digit', });
        const day = dateParsed.toLocaleString('default', { day: '2-digit' });
        const actualDay = [year, month, day].join('-');

        setLastCampsLoading(true);
        PostRequest(getBackendLink() + `/api/v1/products/campaigns/get_last_campaign_for_skus`,
            {
                skus: vals,
                new_date: actualDay,
            })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setLastCampsErr(result.error)
                    } else {
                        setLastCampsErr("")
                        let newLastCampsExist = {}
                        result.result.Records.forEach((element) => {
                            newLastCampsExist[element.Product.ItemSKU] = element.DueDate
                        }
                        );
                        setLastCampsHTML(newLastCampsExist)
                    }
                    setLastCampsLoading(false);
                },
                (error) => {
                    setLastCampsLoading(false);
                    NotificationError('Last campaigns', error.message)
                }
            )
    };


    const [prodsErr, setProdsErr] = useState('');
    const [prods, setProds] = useState();
    const [prodsIn, setProdsIn] = useState();
    const [prodsEx, setProdsEx] = useState();
    const [prodsLoading, setProdsLoading] = useState(false);

    const fetchProds = (brand) => {
        setProdsLoading(true);
        setCatsLoading(true); // We also change cats after products are loaded.
        PostRequest(getBackendLink() + `/api/v1/products/list_for_campaign`,
            {
                brand: brand,
            })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setProdsErr(result.error)
                    } else {
                        setProdsErr("")
                        setProds(result.result.Records);

                        let newProdsIn = []
                        result.result.Records.forEach((element) => {
                            element.ItemSKU = element.Product.ItemSKU
                            newProdsIn.push(element)
                        }
                        );
                        setProdsIn(newProdsIn);

                        let newProdsEx = []
                        result.result.Records.forEach((element) => {
                            element.ItemSKU = element.Product.ItemSKU
                            newProdsEx.push(element)
                        }
                        );
                        setProdsEx(newProdsEx);

                        setAvailableCats(result.result.Records)
                    }
                    setProdsLoading(false);
                },
                (error) => {
                    setProdsLoading(false);
                    NotificationError('Products', error.message)
                }
            )
    };


    // eslint-disable-next-line
    useEffect(() => {
        fetchLastCamps(prodsIn, dueDateValue)
    }, [prodsIn, dueDateValue, prods]);

    const changeProdsEx = (keys = []) => {
        setProdsLoading(true)
        if (keys.length === 0) {
            setProdsEx(prods)
        } else {
            let newData = prodsEx
            keys.forEach((element) => {
                newData = newData.filter((item) => item.Product.ItemSKU !== element);
            }
            );
            setProdsEx(newData);
        }
        setProdsLoading(false)
    }

    const changeProdsIn = (keys = []) => {
        setProdsLoading(true)
        if (keys.length === 0) {
            setProdsIn(prods)
        } else {
            let newData = prodsIn
            keys.forEach((element) => {
                newData = newData.filter((item) => item.Product.ItemSKU !== element);
            }
            );
            setProdsIn(newData);
        }
        setProdsLoading(false)
    }

    const [catsErr, setCatsErr] = useState('');
    const [cats, setCats] = useState();
    const [catsIn, setCatsIn] = useState();
    const [catsLoading, setCatsLoading] = useState(false);

    const fetchCats = (page, size, ids = [], setLists = false) => {
        GetCategories(page, size, ids, setCatsLoading, setCatsErr, setCats)
            .then(
                (result) => {
                    setCats(result.records);
                    if (setLists === true) {
                        // We convert output from server into suitable pretty format.
                        let vals = []
                        result.records.forEach((cat) => {
                            cat.Stringified = StringifiedCategory(cat)
                            vals.push(cat)
                        }
                        );
                        setCatsIn(vals);

                        // We also set the form values, filling with the categories that are already selected.
                        // Meaning all of them.
                        let vals2 = []
                        result.records.forEach((cat) => {
                            vals2.push(cat.ID)
                        }
                        );

                        form.setFieldsValue({
                            CategoriesInclude: vals2,
                        });
                    }
                }
            )
    };

    const setAvailableCats = (keys = []) => {
        let newCats = []
        if (Array.isArray(keys)) {
            keys.forEach((element) => {
                newCats.push(element.Categories.Cat1ID, element.Categories.Cat2ID, element.Categories.Cat3ID)
            }
            );
        }

        fetchCats(0, 0, newCats, true)
    }

    const [resultErrs, setResultErrs] = useState([]);
    const [resultSuccess, setResultSuccess] = useState([]);

    const results = (result) => {
        let errs = 0
        let errsArray = []
        let success = 0
        let successArray = []
        let total = 0

        if (Array.isArray(result.result.Results)) {
            result.result.Results.forEach((element) => {
                total++
                if (element.IsError === true) {
                    errs++
                    errsArray.push(<><strong>{element.Name}</strong>: {errorTranslation(element.Text)}<br /></>)
                }
                if (element.IsError === false) {
                    success++
                    successArray.push(<><strong><a href={`/campaigns/${element.ID}`} target={"_blank"}
                        rel={"noreferrer"}>{element.Name}</a> ({element.Products} products)</strong><br /></>)
                }
            }
            )
            if (errs > 0) {
                NotificationError('Completed!', `Request completed. Created ${success} campaigns of ${total} with ${errs} errors in ${result.result.Time}`)
            } else {
                NotificationSuccess("Completed!", `Request completed. Created ${success} campaigns of ${total} in ${result.result.Time}`)
            }

            setResultErrs(errsArray)
            setResultSuccess(successArray)
        }

        if (result.result.Results === null) {
            NotificationError('0 companies', `Request completed. No campaigns were created under these conditions`)
        }
    }

    const onCreateButtonPushed = (values) => {
        success()
        setResultErrs([])
        setResultSuccess([])

        if (values.CountriesInclude === undefined) {
            values.CountriesInclude = []
        }
        if (values.CountriesExclude === undefined) {
            values.CountriesExclude = []
        }
        if (values.CountriesInclude.length === 0) {
            counts.forEach((element) => {
                if (element.ID !== 255 && element.ID !== 251 && element.ID !== 245) {
                    values.CountriesInclude.push(element.ID)
                }
            }
            )
        }
        if (values.CountriesExclude.length > 0) {
            values.CountriesExclude.forEach((element) => {
                values.CountriesInclude = values.CountriesInclude.filter((item) => item !== element);
            }
            )
        }

        if (values.CategoriesInclude === undefined) {
            values.CategoriesInclude = []
        }
        if (values.CategoriesInclude.length === 0) {
            cats.forEach((element) => {
                values.CategoriesInclude.push(element.ID)
            }
            )
        }

        let useCats = []
        values.CategoriesInclude.forEach((element) => {
            cats.forEach((cat) => {
                if (cat.ID === element) {
                    useCats.push(cat)
                }
            }
            )
        }
        )

        useCats.forEach((cat) => {
            useCats = useCats.filter((item) => cat.ID !== item.ParentID);
        }
        )

        let sendCats = []
        useCats.forEach((cat) => {
            sendCats.push(cat.ID)
        }
        )

        if (values.ProductsInclude === undefined) {
            values.ProductsInclude = []
        }
        if (values.ProductsExclude === undefined) {
            values.ProductsExclude = []
        }
        if (values.ProductsInclude.length === 0) {
            prods.forEach((element) => {
                values.ProductsInclude.push(element.Product.ItemSKU)
            }
            )
        }
        if (values.ProductsExclude.length > 0) {
            values.ProductsExclude.forEach((element) => {
                values.ProductsInclude = values.ProductsInclude.filter((item) => item !== element);
            }
            )
        }

        let reqData = []
        values.CountriesInclude.forEach((market) => {
            sendCats.forEach((cat) => {
                reqData.push({
                    "BrandID": values.BrandID,
                    "Category": cat,
                    "StartDate": values.Dates[0],
                    "DueDate": values.Dates[1],
                    "DiscountPercent": values.DiscountPercent,
                    "Country": market,
                    "Products": values.ProductsInclude,
                })
            }
            )
        }
        )

        PostRequest(getBackendLink() + `/api/v1/products/campaigns/add`, reqData)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        results(result)

                        messageApi.destroy()
                    }
                },
                (error) => {
                    messageApi.destroy()
                    NotificationError('Error', error.message)
                }
            )
    }

    const changeBrand = (values) => {
        form.setFieldsValue({
            ProductsExclude: [],
            ProductsInclude: [],
            CategoriesInclude: [],
        });

        fetchProds(values)
    }

    const filterOptionMarkets = (input, option) => (option?.ListName ?? '').toLowerCase().includes(input.toLowerCase());
    const filterOptionProds = (input, option) => (option?.Product.ItemSKU ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Helmet>
                <title>Campaign constructor || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsCreate />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                {contextHolder}
                <Row className={"bottom-1em"}
                    style={{
                        marginBottom: 16,
                    }}>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                About this page <QuestionCircleOutlined />
                            </Button>
                            <Button href={"/campaigns"}
                                type="primary"
                            >
                                Campaign list
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={prodsErr} prefix={"Products"} />
                <ErrorRow err={countsErr} prefix={"Countries"} />
                <ErrorRow err={catsErr} prefix={"Categories"} />
                <ErrorRow err={lastCampsErr} prefix={"Last campaigns"} />
                <Form
                    name="create_campaign"
                    {...formItemLayout}
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onCreateButtonPushed}
                >
                    <Row className={"bottom-1em"}>
                        <Col xs={24} xl={8}
                            ref={refBrand}
                        >
                            <Form.Item
                                name={`BrandID`}
                                label={`Brand`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Brand',
                                    },
                                ]}
                            >
                                <BrandsSelect
                                    onChange={changeBrand}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="DiscountPercent"
                                label="Discount"
                            >
                                <InputNumber
                                    defaultValue="0"
                                    min="-100"
                                    max="100"
                                    step="0.01"
                                    stringMode
                                    addonAfter="%"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}
                            ref={refDates}
                        >
                            <Form.Item label="Dates" name="Dates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Dates',
                                    },
                                ]}>
                                <RangePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={12}
                            ref={refMarketsInclude}
                        >
                            <Form.Item
                                name={`CountriesInclude`}
                                label={`Markets include`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={countsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={filterOptionMarkets}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.ListName ?? '').toLowerCase().localeCompare((optionB?.ListName ?? '').toLowerCase())
                                    }
                                    options={countsIn}
                                    onChange={changeMarketsEx}
                                    fieldNames={{ label: "ListName", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}
                            ref={refMarketsExclude}
                        >
                            <Form.Item
                                name={`CountriesExclude`}
                                label={`Markets exclude`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={countsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={filterOptionMarkets}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.ListName ?? '').toLowerCase().localeCompare((optionB?.ListName ?? '').toLowerCase())
                                    }
                                    onChange={changeMarketsIn}
                                    options={countsEx}
                                    fieldNames={{ label: "ListName", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={12}
                            ref={refProducts}
                        >
                            <Form.Item
                                name={`ProductsInclude`}
                                label={`Products include`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    onChange={changeProdsEx}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={filterOptionProds}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Product.ItemSKU ?? '').toLowerCase().localeCompare((optionB?.Product.ItemSKU ?? '').toLowerCase())
                                    }
                                    options={prodsIn}
                                    fieldNames={{ label: "ItemSKU", value: "ItemSKU" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item
                                name={`ProductsExclude`}
                                label={`Products exclude`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    onChange={changeProdsIn}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) => (option?.Product.ItemSKU ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Product.ItemSKU ?? '').toLowerCase().localeCompare((optionB?.Product.ItemSKU ?? '').toLowerCase())
                                    }
                                    options={prodsEx}
                                    fieldNames={{ label: "ItemSKU", value: "ItemSKU" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        lastCampsHTML !== null && lastCampsHTML !== undefined && Object.keys(lastCampsHTML).length > 0
                            ?
                            <Row className={"bottom-1em top-1em"}>
                                <Col xs={24} xl={24}>
                                    <Alert
                                        message={<>There are items that have been on campaigns within month from Start
                                            date: <br />
                                            {

                                                Object.keys(lastCampsHTML).map((val, k) => <span k={k}><a
                                                    href={`/products/${val}`} target={"_blank"}
                                                    rel={"noreferrer"}>{val}</a> (until {<TimeFromSeconds
                                                        seconds={lastCampsHTML[val]} />}){k + 1 < Object.keys(lastCampsHTML).length ? <>,</> : <></>} </span>)
                                            }
                                        </>} type="warning">
                                    </Alert>
                                </Col>
                            </Row>
                            :
                            <></>
                    }
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={24}
                            ref={refCats}
                        >
                            <Form.Item
                                name={`CategoriesInclude`}
                                label={`Categories include`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={catsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.stringified ?? '').toLowerCase().localeCompare((optionB?.stringified ?? '').toLowerCase())
                                    }
                                    options={catsIn}
                                    fieldNames={{ label: "Stringified", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={24}>
                            <Form.Item {...tailFormItemLayout}>
                                <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                    ref={refCreate}
                                >
                                    Create campaign
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row className={"bottom-1em top-1em"}>
                    <Col xs={24} xl={24}>
                        {resultErrs.length > 0 ? <Row style={{ "marginTop": "1em" }}>
                            <Col xs={24} xl={24}>
                                <Alert
                                    message="Errors"
                                    description={resultErrs}
                                    type="error"
                                />
                            </Col>
                        </Row> : <></>}
                    </Col>
                </Row>
                <Row className={"bottom-1em top-1em"}>
                    <Col xs={24} xl={24}>
                        {resultSuccess.length > 0 ? <Row style={{ "marginTop": "1em" }}>
                            <Col xs={24} xl={24}>
                                <Alert
                                    message="New campaigns"
                                    description={resultSuccess}
                                    type="success"
                                />
                            </Col>
                        </Row> : <></>}
                    </Col>
                </Row>
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    )
}




