import { Select } from "antd";
import React from "react";
import { Tag } from "antd";

export const EventSourcesMap = {
    "": { id: "", name: "", tag: <Tag>???</Tag> },
    "bh_admin": { id: "bh_admin", name: "Admin", tag: <Tag>Admin</Tag> },
    "system": { id: "system", name: "System", tag: <Tag>System</Tag> },
    "auth_service": { id: "auth_service", name: "Auth Service", tag: <Tag>Auth Service</Tag> },
    "logger_service": { id: "logger_service", name: "Logger Service", tag: <Tag>Logger Service</Tag> },
    "notify_service": { id: "notify_service", name: "Notify Service", tag: <Tag>Notify Service</Tag> },
    "products_service": { id: "products_service", name: "Products Service", tag: <Tag>Products Service</Tag> },
    "eco_service": { id: "eco_service", name: "Ecosystem Service", tag: <Tag>Ecosystem Service</Tag> },
    "integration_more_flo": { id: "integration_more_flo", name: "MoreFlo integration", tag: <Tag>MoreFlo integration</Tag> },
    "integration_more_flo_receipts_import": { id: "integration_more_flo_receipts_import", name: "MoreFlo receipt import", tag: <Tag>MoreFlo receipt import</Tag> },
    "integration_slim4": { id: "integration_slim4", name: "Slim4 integration", tag: <Tag>Slim4 integration</Tag> },
    "integration_feeds_v2": {
        id: "integration_feeds_v2",
        name: "Feeds integration (v2)",
        tag: <Tag>Feeds integration (v2)</Tag>
    },
    "integration_gamifiera_user_feed_v2": {
        id: "integration_gamifiera_user_feed_v2",
        name: "Gamifiera users integration (v2)",
        tag: <Tag>Gamifiera users integration (v2)</Tag>
    },
    "integration_clickup": {
        id: "integration_clickup",
        name: "ClickUp integration",
        tag: <Tag>ClickUp integration</Tag>
    },
    "integration_replicator_v1": {
        id: "integration_replicator_v1",
        name: "Replicator integration (v1)",
        tag: <Tag>Replicator integration (v1)</Tag>
    },
    "redis_product_replicator_v3": {
        id: "redis_product_replicator_v3",
        name: "Redis product replicator (v3)",
        tag: <Tag>Redis product replicator (v3)</Tag>
    },
    "replicator_voyado_uids": {
        id: "replicator_voyado_uids",
        name: "Voyado UIDs replicator",
        tag: <Tag>Voyado UIDs replicator</Tag>
    },
    "replicator_info_baleen_orders": {
        id: "replicator_info_baleen_orders",
        name: "Infobaleen Orders replicator",
        tag: <Tag>Infobaleen Orders replicator</Tag>
    },
    "replicator_info_baleen_users": {
        id: "replicator_info_baleen_users",
        name: "Infobaleen Users replicator",
        tag: <Tag>Infobaleen Users replicator</Tag>
    },
    "dev_debug": {
        id: "dev_debug",
        name: "(Developer) debug",
        tag: <><Tag color={"magenta"}>(Developer) debug</Tag></>
    },
}

export const EventSources = [
    { "id": "", "name": "" },
    { "id": "bh_admin", "name": "Admin" },
    { "id": "system", "name": "System" },
    { "id": "auth_service", "name": "Auth Service" },
    { "id": "logger_service", "name": "Logger Service" },
    { "id": "notify_service", "name": "Notify Service" },
    { "id": "products_service", "name": "Products Service" },
    { "id": "eco_service", "name": "Ecosystem Service" },
    { "id": "integration_more_flo", "name": "MoreFlo integration" },
    { "id": "integration_more_flo_receipts_import", "name": "MoreFlo receipt import" },
    { "id": "integration_slim4", "name": "Slim4 integration" },
    { "id": "integration_feeds_v2", "name": "Feeds integration (v2)" },
    { "id": "integration_replicator_v1", "name": "Replicator integration (v1)" },
    { "id": "redis_product_replicator_v3", "name": "Redis product replicator (v3)" },
    { "id": "replicator_voyado_uids", "name": "Voyado UIDs replicator" },
    { "id": "replicator_info_baleen_orders", "name": "Infobaleen Orders replicator" },
    { "id": "replicator_info_baleen_users", "name": "Infobaleen Users replicator" },
    { "id": "dev_debug", "name": "(Developer) debug" },
]

export const GetEventSourcesSelect = (props) => {
    return (
        <Select
            onChange={(value) => {
                props.onChange(value)
            }}
            options={EventSources}
            fieldNames={{ label: "name", value: "id" }}
        >
        </Select>
    )
}

export const EventLevelsMap = {
    "": { id: "", name: "", tag: <Tag>???</Tag> },
    "info": { id: "info", name: "Info", tag: <Tag>Info</Tag> },
    "warning": { id: "warning", name: "Warning", tag: <Tag color="gold">Warning</Tag> },
    "error": { id: "error", name: "Error", tag: <Tag color="volcano">Error</Tag>, },
    "danger": { id: "danger", name: "Danger", tag: <Tag color="red">Danger</Tag> },
    "critical": { id: "critical", name: "Critical", tag: <Tag color="#f50">Critical</Tag> },
}

export const EventLevels = [
    { "id": "", "name": "" },
    { "id": "info", "name": "Info" },
    { "id": "warning", "name": "Warning" },
    { "id": "error", "name": "Error" },
    { "id": "danger", "name": "Danger" },
    { "id": "critical", "name": "Critical" },
]

export const GetEventLevelsSelect = (props) => {
    return (
        <Select
            onChange={(value) => {
                props.onChange(value)
            }}
            options={EventLevels}
            fieldNames={{ label: "name", value: "id" }}
        >
        </Select>
    )
}
