import React from 'react';
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Spinner, SpinProps } from "../../components/spinners";
import { ErrorRow } from "../../components/errorBlock";
import { useParams } from "react-router-dom";
import { Card, Descriptions, Table } from "antd";
import { Col, Row } from 'antd';
import { PostRequestInternalAPI } from "../../components/backend/postRequest";
import { GetTagFromValue } from "../../components/formattingValues";
import { NotificationSuccess } from "../../components/notifications";
import { CanEditMoreFlo, CanSeeMoreFlo } from "../../components/backend/backendRoles";
import { ArrowRightOutlined, CloudDownloadOutlined, ApiOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
    BreadcrumbsProductsProductMoreFlo,
} from "../../components/breadcrumbs/breadcrumbsProducts";
import { InlineMenu } from "../../components/menus/inline";
import { GetProductPageMenuItems } from "../../components/menus/itemsProductPage";
import {
    Groups,
    AccountCodes,
    ColumnsMoreFloHistory as columns, GetLinkProductMoreFloAPI, GetLinkCSVMoreFloHistory,
} from "../../components/moreflo";
import { ProductLinkInline } from "../../components/product";
import { HandleTableChange } from "../../components/tables/tableFunctions";
import { Helmet } from "react-helmet";

export function ProductMoreFlo(props) {
    const { itemSKU } = useParams();
    const [apiError, setApiError] = useState("");
    const [apiFetching, setApiFetching] = useState(true);
    const [apiData, setApiData] = useState({
        "IsActive": false,
        "IsWebshopArticle": false,
        "IsBookable": false,
        "IsOpenArticle": false,
        "IsQuickButton": false
    });
    const [historyFetching, setHistoryFetching] = useState(true);
    const [historyError, setHistoryError] = useState("");
    const [historyData, setHistoryData] = useState([]);
    const [historyTableParams, setHistoryTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChange = () => (
        pagination,
        filters,
        sorter
    ) => {
        HandleTableChange(
            pagination,
            filters,
            sorter,
            historyTableParams,
            setHistoryTableParams,
            setHistoryData,
        )
    }

    // eslint-disable-next-line
    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/integrations/more_flo/get_product_from_api?sku=${itemSKU}`,
            setApiFetching,
            setApiError,
            setApiData
        ).then()
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/integrations/more_flo/cache_records_get?page=${historyTableParams.pagination.current}&size=${historyTableParams.pagination.pageSize}&batch_id=all&sku=${itemSKU}&barcode=all`,
            setHistoryFetching,
            setHistoryError,
            setHistoryData
        ).then()
        // eslint-disable-next-line
    }, [historyTableParams.pagination.current, historyTableParams.pagination.pageSize]);

    useEffect(() => {
        setHistoryTableParams({
            ...historyTableParams,
            pagination: {
                ...historyTableParams.pagination,
                total: historyData.Total,
            },
        });
        // eslint-disable-next-line
    }, [historyData]);

    const toggleProduct = () => {
        PostRequestInternalAPI(`/api/v1/integrations/more_flo/update_products_in_api`, [
            { "ArticleNumber": apiData.ArticleNumber, "IsActive": !apiData.IsActive, },
            null, null, null,
        ])
            .then(() => {
                NotificationSuccess("Updated", "Product state changed")
                let ad = apiData
                ad.IsActive = !ad.IsActive
                setApiData(ad)
            }
            )
    };

    const menuItems = GetProductPageMenuItems(itemSKU)
    menuItems.push(
        {
            label: <a
                href={GetLinkCSVMoreFloHistory("all", itemSKU, "all")}
                target={"_blank"} rel="noreferrer">Download CSV</a>,
            icon: <CloudDownloadOutlined />,
        },
        {
            label: CanSeeMoreFlo(props.token.grants) ? <a
                href={GetLinkProductMoreFloAPI(itemSKU)}
                target={"_blank"} rel="noreferrer">Check in API</a> : "Check in API",
            icon: <ArrowRightOutlined />,
            disabled: !CanSeeMoreFlo(props.token.grants)
        },
        {
            label: "Enable / Disable product",
            danger: true,
            icon: <ApiOutlined />,
            disabled: !CanEditMoreFlo(props.token.grants) || apiError !== "" || apiFetching,
            onClick: toggleProduct
        },
    )

    return (
        <>
            <Helmet>
                <title>Product data (MoreFlo) || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsProductMoreFlo sku={itemSKU} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <InlineMenu items={menuItems} />
                    </Col>
                </Row>
            </div>

            <ErrorRow err={apiError} prefix={"API"} />
            {apiFetching === true ? <Spinner tip={""} /> : <></>}
            {apiError === "" && !apiFetching ? (
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Card
                                title={itemSKU + " More Flo API"}
                                bordered={false}
                                style={{ backgroundColor: "transparent", borderRadius: 0, borderColor: "transparent" }}
                            >
                                <HTMLApiData data={apiData} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : <></>}

            <ErrorRow err={historyError} prefix={"Cache"} />
            {historyError === "" ? (
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={historyData.Records}
                                pagination={historyTableParams.pagination}
                                loading={historyFetching ? SpinProps : false}
                                size={"small"}
                                style={{ borderRadius: 0 }}
                                onChange={handleTableChange}
                                scroll={{
                                    y: 500,
                                    x: 4000,
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            ) : <></>}
        </>
    )
}

function HTMLApiData(props) {
    let apiData
    apiData = props.data
    return (
        <Descriptions>
            <Descriptions.Item
                label="ArticleNumber"><ProductLinkInline sku={apiData.ArticleNumber} /></Descriptions.Item>
            <Descriptions.Item
                label="ArticleName">{apiData.ArticleName}</Descriptions.Item>
            <Descriptions.Item
                label="AccountCodeId">{AccountCodes[apiData.AccountCodeId] ? <>
                    <a href={`https://api.moreflo.com/v2/accountcodes/${apiData.AccountCodeId}`}
                        target={"_blank"}
                        rel="noreferrer">{AccountCodes[apiData.AccountCodeId]}</a></> : <>{apiData.AccountCodeId}</>}</Descriptions.Item>
            <Descriptions.Item
                label="ArticleInfoUpdateTime">{apiData.ArticleInfoUpdateTime}</Descriptions.Item>
            <Descriptions.Item
                label="ArticleType">{apiData.ArticleType}</Descriptions.Item>
            <Descriptions.Item
                label="Barcode">{apiData.Barcode}</Descriptions.Item>
            <Descriptions.Item
                label="BarcodeAlternatives">{apiData.BarcodeAlternatives}</Descriptions.Item>
            <Descriptions.Item
                label="GroupId">{Groups[apiData.GroupId] ? <>
                    <a href={`https://api.moreflo.com/v2/groups/${apiData.GroupId}`}
                        target={"_blank"}
                        rel="noreferrer">{Groups[apiData.GroupId]}</a></> : <>{apiData.GroupId}</>}</Descriptions.Item>
            <Descriptions.Item
                label="InputPrice">{apiData.InputPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Is Active">{GetTagFromValue(apiData.IsActive)}</Descriptions.Item>
            <Descriptions.Item
                label="Is Bookable">{apiData.IsBookable.toString()}</Descriptions.Item>
            <Descriptions.Item
                label="Is Open Article">{apiData.IsOpenArticle.toString()}</Descriptions.Item>
            <Descriptions.Item
                label="Is Quick Button">{apiData.IsQuickButton.toString()}</Descriptions.Item>
            <Descriptions.Item
                label="Is Webshop Article">{apiData.IsWebshopArticle.toString()}</Descriptions.Item>
            <Descriptions.Item
                label="Quantity">{apiData.Quantity}</Descriptions.Item>
            <Descriptions.Item
                label="Quantity Updated">{apiData.QuantityUpdatedTime}</Descriptions.Item>
            <Descriptions.Item
                label="Recommended Price">{apiData.RecommendedPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Retail Price">{apiData.RetailPrice}</Descriptions.Item>
            <Descriptions.Item
                label="Unit">{apiData.Unit}</Descriptions.Item>
        </Descriptions>
    )
}