import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Tag, Input, Space, InputNumber, Button } from 'antd';
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { ErrorRow } from "../../components/errorBlock";
import { NotificationError } from "../../components/notifications";
import { responseCodes } from "../../components/backend/backend";
import { BreadcrumbsRequestLogs } from "../../components/breadcrumbs/breadcrumbsStat";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore } from "../../components/dateAndTime";
import { GetUserSelect } from "../../components/getUsers";
import { RequestLogsListTable } from "../../subpages/stat/logRequests";
import { MonitorOutlined } from "@ant-design/icons";

export const RequestLogsList = (props) => {
    const [err, setErr] = useState("");
    const [endpoints, setEndpoints] = useState([]);
    const [loadingEndpoints, setLoadingEndpoints] = useState(false);
    const [refreshData, setRefreshData] = useState(0);

    const fetchEndpointsList = () => {
        setLoadingEndpoints(true);
        GetRequest(getBackendLink() + `/api/v1/dev/endpoints/get_list/json?page=1&size=500`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")

                        let newEndpoints = [
                            { address: "", name: " " }
                        ]

                        result.result.records.map((x) => (
                            newEndpoints.push({
                                address: x.address,
                                name: <>{x.method} {x.address} ({x.comment})</>,
                            })
                        ))
                        setEndpoints(newEndpoints)
                    }
                    setLoadingEndpoints(false);
                },
                (error) => {
                    setLoadingEndpoints(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchEndpointsList();
        // eslint-disable-next-line
    }, []);

    const [minResponseSize, setMinResponseSize] = useState(0);
    const [minRequestSize, setMinRequestSize] = useState(0);
    const [minDuration, setMinDuration] = useState(0);
    const [responseRode, setResponseRode] = useState(0);
    const [method, setMethod] = useState("");
    const [remoteAddr, setRemoteAddr] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [userID, setAuthorUser] = useState("");
    const [sessionID, setSessionID] = useState("");
    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const [total, setTotal] = useState(0);

    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsRequestLogs />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        Total: <Tag color={"purple"}>{total}</Tag>
                        <Button type="primary"
                            onClick={() => {
                                setRefreshData(refreshData + 1)
                            }}
                        >
                            Get logs <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item label="Dates" name="Dates"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Before and after',
                                },
                            ]}>
                            <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`User`}
                            label={`User`}
                        >
                            <GetUserSelect grants={props.grants} setUser={setAuthorUser} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Endpoint`}
                            label={`Endpoint`}
                        >
                            <Select
                                loading={loadingEndpoints}
                                onChange={(value) => {
                                    setEndpoint(value)
                                }}
                                options={endpoints}
                                fieldNames={{ label: "name", value: "address" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Response code`}
                            label={`Response code`}
                        >
                            <Select
                                onChange={(value) => {
                                    setResponseRode(value)
                                }}
                                options={responseCodes}
                                fieldNames={{ label: "name", value: "code" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Form.Item name="min_duration" label="Min. duration (ms)">
                                <InputNumber min={0} defaultValue={0} onChange={(e) => {
                                    setMinDuration(e)
                                }} />
                            </Form.Item>
                            <Form.Item name="min_request_size" label="Min. req. size (bytes)">
                                <InputNumber min={0} defaultValue={0} onChange={(e) => {
                                    setMinRequestSize(e)
                                }} />
                            </Form.Item>
                            <Form.Item name="min_response_size"
                                label="Min. resp. size (bytes)">
                                <InputNumber min={0} defaultValue={0} onChange={(e) => {
                                    setMinResponseSize(e)
                                }} />
                            </Form.Item>
                            <Form.Item name="remote_addr"
                                label="IP Address">
                                <Input onChange={(e) => {
                                    setRemoteAddr(e.target.value)
                                }} />
                            </Form.Item>
                            <Form.Item name="session_id"
                                label="SessionID">
                                <Input onChange={(e) => {
                                    setSessionID(e.target.value)
                                }} />
                            </Form.Item>
                            <Form.Item name="method"
                                label="Method">
                                <Input onChange={(e) => {
                                    setMethod(e.target.value)
                                }} />
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <RequestLogsListTable
                            currentSessionID={props.sessionID}
                            minRequestSize={minRequestSize}
                            minResponseSize={minResponseSize}
                            minDuration={minDuration}
                            responseRode={responseRode}
                            method={method}
                            remoteAddr={remoteAddr}
                            endpoint={endpoint}
                            userID={userID}
                            sessionID={sessionID}
                            before={before}
                            after={after}
                            refreshData={refreshData}

                            setTotal={setTotal}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
